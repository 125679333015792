<script>
import ChallengeBannerPipe from "./components/ChallengeBannerPipe.vue";
import { SuawMainContent, SuawPageHeaderActions, SuawDivider, SuawButton, SuawModal } from "@/components";
import * as ChallengeApi from "./challengeApi";
import ChallengePromptListPipe from "./components/ChallengePromptListPipe.vue";
import { GetChallengeUser, GetChallengeStatus } from "./challengeOperations.gql";

export default {
  name: "Challenge",
  components: {
    ChallengeBannerPipe,
    ChallengePromptListPipe,
    SuawMainContent,
    SuawPageHeaderActions,
    SuawDivider,
    SuawButton,
    SuawModal
  },
  data() {
    return {
      loadingCount: 0,
      showModal: false,
      modalTitle: "",
      modalConfirmAction: null
    };
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    challengeId() {
      return this.$route.params.id;
    },
    userId() {
      return this.$auth.isAuthenticated ? this.$auth.user.id : null;
    },
    hasAccepted() {
      return this.resultChallenge?.challenge_users?.length > 0;
    },
    hasEnded() {
      return new Date() > new Date(this.resultGetChallengeStatus?.available_until);
    }
  },
  methods: {
    showAuthModal() {
      this.showModal = true;
      this.modalTitle = "You must be signed in to accept a challenge. You will be redirected to the sign in page.";
      this.modalConfirmAction = this.redirectToSignIn;
    },

    showAcceptModal() {
      if (!this.$auth.isAuthenticated) {
        this.showAuthModal();
        return;
      }
      this.showModal = true;
      this.modalTitle =
        "Are you sure you want to accept this challenge? You will receive an email reminder when each prompt is unlocked. You must submit prompt submissions before the challenge is over.";
      this.modalConfirmAction = this.acceptChallenge;
    },

    showUnacceptModal() {
      if (!this.$auth.isAuthenticated) {
        this.showAuthModal();
        return;
      }
      this.showModal = true;
      this.modalTitle = "Are you sure you want to unaccept this challenge?";
      this.modalConfirmAction = this.unacceptChallenge;
    },

    redirectToSignIn() {
      this.$router.push({
        name: "LogIn",
        query: {
          redirectUrl: this.$route.fullPath
        }
      });
    },

    async acceptChallenge() {
      const result = await ChallengeApi.acceptChallenge(this.challengeId, this.userId);
      this.handleActionResult(result);
    },

    async unacceptChallenge() {
      const result = await ChallengeApi.unacceptChallenge(this.challengeId, this.userId);
      this.handleActionResult(result);
    },

    handleActionResult(result) {
      this.showModal = false;
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
        return;
      }
      this.$apollo.queries.resultChallenge.refetch();
    },

    onCancelModal() {
      this.showModal = false;
    }
  },

  apollo: {
    resultChallenge: {
      query: GetChallengeUser,
      variables() {
        return {
          id: this.$route.params.id,
          userId: this.$auth.user.id
        };
      },
      skip() {
        return !this.$auth.isAuthenticated;
      },
      loadingKey: "loadingCount"
    },
    resultGetChallengeStatus: {
      query: GetChallengeStatus,
      variables() {
        return {
          id: this.$route.params.id
        };
      },
      skip() {
        return !this.$route.params.id;
      },
      loadingKey: "loadingCount"
    }
  }
};
</script>

<template>
  <SuawMainContent size="large-small-gap" :show-gradient="true">
    <SuawPageHeaderActions back-text="Back to All Challenges" :back-route-object="{ name: 'ChallengeDiscovery' }" />
    <SuawDivider />
    <SuawModal v-if="showModal" :modal-title="modalTitle" modal-type="confirmation" :toggle-button-confirm="modalConfirmAction" :toggle-button-cancel="onCancelModal" />
    <div v-if="isLoading">
      Loading...
    </div>
    <ChallengeBannerPipe v-if="challengeId" :challenge-id="challengeId">
      <template #button>
        <SuawButton
          class="suaw-challenge-banner__button"
          size="large"
          :type="hasEnded ? 'disabled' : hasAccepted ? 'primary-light' : 'primary'"
          :button-text="hasAccepted ? 'UNACCEPT CHALLENGE' : 'ACCEPT CHALLENGE!'"
          @click="hasAccepted ? showUnacceptModal() : showAcceptModal()"
        />
      </template>
    </ChallengeBannerPipe>
    <SuawDivider />
    <ChallengePromptListPipe v-if="challengeId" :challenge-id="challengeId" />
    <div v-if="!isLoading && !challengeId">
      Challenge Not Found
    </div>
  </SuawMainContent>
</template>
