<script>
import ChallengePromptListView from "./ChallengePromptListView.vue";
import { GetChallengeForPromptList } from "../challengeOperations.gql";

export default {
  name: "ChallengePromptListPipe",
  components: {
    ChallengePromptListView
  },
  props: {
    challengeId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loadingCount: 0
    };
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    promptIds() {
      return this.resultChallenge?.prompt_sort || [];
    }
  },
  apollo: {
    resultChallenge: {
      query: GetChallengeForPromptList,
      variables() {
        return {
          id: this.challengeId
        };
      },
      loadingKey: "loadingCount"
    }
  }
};
</script>

<template>
  <ChallengePromptListView :prompt-ids="promptIds" :is-loading="isLoading" />
</template>
