<script>
import ChallengePromptPipe from "./ChallengePromptPipe.vue";
import { SuawHeading, SuawEmptyState, SuawInputGroup } from "@/components";
export default {
  name: "ChallengePromptListView",
  components: {
    ChallengePromptPipe,
    SuawHeading,
    SuawEmptyState,
    SuawInputGroup
  },
  props: {
    promptIds: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<template>
  <SuawInputGroup direction="column" group-gap="triple">
    <SuawHeading level="3" content="Challenge Prompts" />
    <template v-if="!isLoading">
      <div class="prompts-list">
        <ChallengePromptPipe v-for="promptId in promptIds" :key="promptId" :prompt-id="promptId" />
      </div>
    </template>
    <SuawEmptyState v-else message="Loading prompts..." />
  </SuawInputGroup>
</template>

<style scoped>
.prompts-list {
  display: flex;
  flex-direction: column;
  gap: var(--sem-space-triple);
}
</style>
