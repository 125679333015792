import { render, staticRenderFns } from "./ChallengePromptListView.vue?vue&type=template&id=dba6870c&scoped=true"
import script from "./ChallengePromptListView.vue?vue&type=script&lang=js"
export * from "./ChallengePromptListView.vue?vue&type=script&lang=js"
import style0 from "./ChallengePromptListView.vue?vue&type=style&index=0&id=dba6870c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dba6870c",
  null
  
)

export default component.exports